import Button from 'components/Button/Button';
import DeliveryPostalCodeSelect from 'components/DeliveryPostalCodeSelect/DeliveryPostalCodeSelect';
import Flex from 'components/Flex/Flex';
import FormError from 'components/Form/FormError/FormError';
import Typography from 'components/Typography/Typography';
import { CURRENT_LOCATION_FORM_FIELDS, CURRENT_LOCATION_FORM_ID } from 'constants/myAccount';
import useAuthResolver from 'hooks/useAuthResolver';
import useLocation from 'hooks/useLocation';
import { useSelector } from 'hooks/useSelector';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useUserActions } from 'store/user';
import { destructurePostalCode, formatPostalCode } from 'utils/postalCodeUtil';
import Modal from '../Modal/Modal';

interface EditLocationPopupProps {
  showLocationModal?: boolean;
  toggleModal: () => void;
}

const EditLocationPopup = ({ showLocationModal, toggleModal }: EditLocationPopupProps) => {
  const { formatMessage } = useIntl();

  const { anonymous, user } = useAuthResolver();

  const { updateCurrentLocation } = useUserActions();

  const activeDefaultCountry = useSelector((state) => state.postalCode?.activeDefaultCountry);

  const removeLocation = () => {
    updateCurrentLocation(null, anonymous);
    toggleModal();
  };

  const handleSubmit = (values: Record<string, string>) => {
    const newLocation = values?.[CURRENT_LOCATION_FORM_FIELDS.LOCATION.name];

    const newFormattedLocation = destructurePostalCode(newLocation);

    const location = {
      country: activeDefaultCountry,
      name: newFormattedLocation?.town,
      postalCode: newFormattedLocation?.postalCode,
    };

    updateCurrentLocation(location, anonymous);
    toggleModal();
  };

  const userLocation = formatPostalCode(
    user?.currentLocation?.name,
    Number(user?.currentLocation?.postalCode),
    user?.currentLocation?.country?.isocode,
  );

  const cookieLocation = useLocation();

  const initialLocation = user?.currentLocation ? userLocation : cookieLocation;
  const initialValues = { [CURRENT_LOCATION_FORM_FIELDS.LOCATION.name]: initialLocation };

  return (
    <Modal
      closeModal={toggleModal}
      show={!!showLocationModal}
      title={formatMessage({ id: 'general_location_popup_title' })}
    >
      <Form
        initialValues={initialValues}
        mutators={{
          updatePostalCode: (value, state, utils) => {
            const newLocation = value[0];
            utils.changeValue(state, CURRENT_LOCATION_FORM_FIELDS.LOCATION.name, () => newLocation);
          },
        }}
        onSubmit={handleSubmit}
        render={({ form, handleSubmit }) => (
          <form id={CURRENT_LOCATION_FORM_ID} onSubmit={handleSubmit}>
            <FormError />

            <Typography type="p">{formatMessage({ id: 'my_account_address_form_location_title' })}</Typography>

            <DeliveryPostalCodeSelect
              id={CURRENT_LOCATION_FORM_FIELDS.LOCATION.id}
              label={formatMessage({
                id: CURRENT_LOCATION_FORM_FIELDS.LOCATION.label,
              })}
              name={CURRENT_LOCATION_FORM_FIELDS.LOCATION.name}
              onSubmitPostalCode={form.mutators.updatePostalCode}
              withIcons
            />

            <Flex gap={100} justifyContent="flex-end">
              <Button color="secondary" onClick={removeLocation} outlined>
                {formatMessage({ id: 'general_location_popup_remove_link' })}
              </Button>
              <Button type="submit">{formatMessage({ id: 'general_location_popup_save_btn' })}</Button>
            </Flex>
          </form>
        )}
      />
    </Modal>
  );
};

export default EditLocationPopup;
