import { FontSize, FontWeight, TextDecoration } from 'constants/styling';
import { ReactNode } from 'react';
import { ButtonTypeAttribute } from 'types/Button';
import { Color } from 'types/Color';
import { StyledButton, StyledLabel, StyledLabelProps } from './ButtonText.styled';

interface ButtonTextProps extends StyledLabelProps {
  children?: ReactNode;
  className?: string;
  color?: Color;
  disabled?: boolean;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  onClick?: () => void;
  textDecoration?: TextDecoration;
  type?: ButtonTypeAttribute;
}

const ButtonText = ({
  children,
  className,
  color = 'primary-400',
  colorOnHover = 'primary-300',
  disabled,
  onClick,
  type,
  ...typographyProps
}: ButtonTextProps) => (
  <StyledButton
    onClick={(event) => {
      event.stopPropagation();
      onClick?.();
    }}
    disabled={disabled}
    className={className}
    type={type}
  >
    <StyledLabel
      colorOnHover={disabled ? undefined : colorOnHover}
      color={disabled ? 'whisper-300' : color}
      {...typographyProps}
    >
      {children}
    </StyledLabel>
  </StyledButton>
);

export default ButtonText;
