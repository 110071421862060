import { ReactNode } from 'react';
import { Modal } from '..';
import { StyledIFrame } from './IFrameOverlay.styled';

interface IFrameOverlayProps {
  children?: ReactNode;
  className?: string;
  iFrameUrl: string;
  show?: boolean;
  title: string;
  toggleOverlay: (event?: MouseEvent) => void;
}

const IFrameOverLay = ({ children, className, iFrameUrl, show, title, toggleOverlay }: IFrameOverlayProps) => (
  <Modal className={`iframe-overlay ${className}`} closeModal={toggleOverlay} show={!!show} title={title}>
    <>
      {iFrameUrl && <StyledIFrame frameBorder="0" src={iFrameUrl} title="energyLabel" />}
      {children}
    </>
  </Modal>
);

export default IFrameOverLay;
