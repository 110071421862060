import { FlyOver } from 'components/Layout';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'ui';
import { useWindowSize } from 'usehooks-ts';

type ModalProps = {
  children: ReactNode;
  className?: string;
  closeModal?: () => void;
  flyOverOnMobile?: boolean;
  show: boolean;
  side?: 'left' | 'right';
  title?: string;
  withCloseIcon?: boolean;
};

const Modal = ({
  children,
  className,
  closeModal,
  flyOverOnMobile = true,
  show,
  side,
  title,
  withCloseIcon,
}: ModalProps) => {
  const { formatMessage } = useIntl();
  const { width } = useWindowSize();
  const isMobile = width < 768;

  if (!isMobile || !flyOverOnMobile) {
    return (
      <Dialog
        aria-labelledby={formatMessage({ id: 'modal_label' })}
        open={show}
        onOpenChange={(open) => {
          if (!open && !!closeModal) closeModal();
        }}
      >
        <DialogContent className={className}>
          <DialogHeader>{title && <DialogTitle>{title}</DialogTitle>}</DialogHeader>

          <div>{children}</div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <FlyOver onOpenChange={() => closeModal?.()} open={show} title={title} side={side} withCloseIcon={withCloseIcon}>
      {children}
    </FlyOver>
  );
};

export default Modal;
