import useAuthResolver from 'hooks/useAuthResolver';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import { useIntl } from 'react-intl';
import { useCustomerFeedbackActions, useCustomerFeedbackError, useCustomerFeedbackOpen } from 'store/customerFeedback';
import { useError } from 'store/error';
import { Feedback, FeedbackForm } from 'types/CustomerFeedback';
import { Text } from 'ui';
import config from '../../../config';
import Alert from '../Alert/Alert';
import Modal from '../Modal/Modal';
import CustomerFeedbackForm from './CustomerFeedbackForm/CustomerFeedbackForm';

const CustomerFeedbackModal = () => {
  const { formatMessage } = useIntl();
  const router = useRouter();

  const { user } = useAuthResolver();
  const cart = useSelector((state) => state.cart.cart);

  const error = useError();
  const show = useCustomerFeedbackOpen();
  const feedbackError = useCustomerFeedbackError();
  const { setCustomerFeedbackOpen, submitCustomerFeedback } = useCustomerFeedbackActions();

  const submitFeedback = async (values: FeedbackForm) => {
    const page = router.asPath ? `${config.env.httpOrigin}${router.asPath}` : undefined;

    const userAgent = window?.navigator?.userAgent;
    const feedback: Feedback = {
      cartId: cart?.guid ?? '',
      email: user?.uid ?? '',
      error: error ?? 'unknown error',
      message: values && values.remarks,
      page,
      userAgent,
    };
    await submitCustomerFeedback(feedback);
  };

  const onClose = () => {
    setCustomerFeedbackOpen(false);
  };

  return (
    <Modal
      className="customer-feedback"
      closeModal={onClose}
      show={show}
      title={formatMessage({ id: 'general_customer_feedback_modal_title' })}
    >
      <>
        <div className="feedback-info">
          <Text className="mb-4">{formatMessage({ id: 'general_customer_feedback_modal_info' })}</Text>
          {!!feedbackError && (
            <Alert type="danger">
              {formatMessage({
                id: 'general_customer_feedback_modal_submit_err',
              })}
            </Alert>
          )}
        </div>
        <CustomerFeedbackForm onSubmit={submitFeedback} />
      </>
    </Modal>
  );
};

export default CustomerFeedbackModal;
