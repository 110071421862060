import Typography from 'components/Typography/Typography';
import styled from 'styled-components';
import { color } from 'theme';
import { Color } from 'types/Color';

export interface StyledLabelProps {
  colorOnHover?: Color;
}

export const StyledButton = styled.button`
  background-color: unset;
  border: unset;
  padding: 0px;
`;

export const StyledLabel = styled(Typography)<StyledLabelProps>`
  &:hover {
    color: ${({ colorOnHover }) => color(colorOnHover)};
  }
`;
