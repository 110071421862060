import { ReviewFormSchema } from 'components/Product/ProductDetail/ProductReviews/ReviewForm/ReviewFormValidation';
import { ReviewBody } from 'types/Review';
import { User } from 'types/User';
import { formatISO } from './dateUtil';

/**
 * Function which returns an array of rating star values by a given rating
 */
export const generateRatingObject = (rating = 0) => {
  const ratings = [];

  for (let i = 0.5; i <= 5; i += 0.5) {
    const check = rating - i;
    let value = 0;

    if (check >= -0.25) {
      value = 1;
    } else if (check >= -0.75) {
      value = 0.5;
    }

    ratings.push({ value });
  }

  return ratings;
};

/**
 * Function which calculates the selected rating based on the cursor position
 * @param {number} currentPos - the current cursor position
 * @param {number} minWidth - the minimum width of the rating stars component
 * @param {number} maxWidth - the maximum width of the rating stars component
 * @returns {number} - the selected rating
 */
export const getCurrentRating = (currentPos: number, minWidth: number, maxWidth: number) => {
  const step = maxWidth / 10.0;
  let previousStep = minWidth;
  let rating = 0.5;
  let currentRating = 0;
  for (let i = step; i <= maxWidth; i += step) {
    if (currentPos > previousStep && currentPos <= i) {
      currentRating = rating;
      break;
    }
    previousStep = i;
    rating += 0.5;
  }
  return currentRating;
};

/**
 * Function which returns the review summary title translation key based on the current rating
 * @param {number} rating - the current rating
 * @returns {string} the review summary title translation key
 */
export const getReviewMessage = (rating = 0) => {
  const thresholds = [
    { limit: 1.5, message: 'review_summery_bad_product_title' },
    { limit: 2.5, message: 'review_summery_no_good_product_title' },
    { limit: 3.5, message: 'review_summery_good_product_title' },
    { limit: 4.5, message: 'review_summery_great_product_title' },
    { limit: 5.5, message: 'review_summery_fantastic_product_title' },
  ];

  const { message } = thresholds.find(({ limit }) => rating < limit) || { message: 'review_summery_no_review_title' };

  return message;
};

/**
 * Function which maps the pros and cons form values to an array
 * @param {object} values - the current form values
 * @param {Array} array - the array user for the pros or cons values
 * @param {boolean} pro - flag which check if the value are pro or con values
 * @returns {undefined}
 */
const mapProsAndConsToArray = (values: ReviewFormSchema['cons'] | ReviewFormSchema['pros'], pro = true) =>
  values
    ? values
        .map((value) => (pro ? value.advantage : value.disadvantage))
        .filter((value): value is string => Boolean(value))
    : [];

/**
 * Function which maps the review form values into an object
 * @param {object} values - the current form values
 * @param {object} user - the current logged on user
 * @returns {object} the rating object
 */
export const mapFormValuesToReviewObject = (values: ReviewFormSchema, user: User | null): ReviewBody => {
  const positivePoints = mapProsAndConsToArray(values.pros, true);
  const negativePoints = mapProsAndConsToArray(values.cons, false);

  const mapToNumber = (value: string) => (typeof value === 'number' ? value : undefined);

  return {
    alias: values.name,
    comment: values.review_text,
    date: formatISO(new Date()),
    designRating: mapToNumber(values.designRating),
    functionalityRating: mapToNumber(values.functionalityRating),
    headline: values.review_title,
    negativePoints,
    positivePoints,
    priceQualityRating: mapToNumber(values.qualityRating),
    principal: {
      name: user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : undefined,
      uid: user?.uid,
    },
    userExperienceRating: mapToNumber(values.userExperienceRating),
  };
};

/**
 * Function which validates the review rating values
 * @param {object} review - the current review
 * @returns {string|undefined} the review rating error translation id
 */
export const validateRatings = (review: ReviewBody) =>
  !!(review.userExperienceRating || review.designRating || review.priceQualityRating || review.functionalityRating);
